//import logo from './logo.svg';

import './App.css';
import { useEffect, useState } from 'react';
import Etusivu from './components/Etusivu'
import Hylkykortti from './components/Hylkykortti'
import Hylkylista from './components/Hylkylista';
//import Map from './components/maps/Map';
import SearchForm from './components/SearchForm';
import Menu from './components/Menu';
import { Container,Row,Col } from 'react-bootstrap';
import { Routes, Route, Link, useMatch} from "react-router-dom"
import Page from './components/Page';
//import SearchAutocomplete from "./components/SearchAutocomplete"

import getMarkers  from './services/map'
import hylkyservices from './services/hylkykortti'
//import { inRange } from 'lodash';
import PositionPrecisionFilter from './components/PositionPrecisionFilter';
import WreckTypeFilter from './components/WreckTypeFilter';
import WreckMap from './components/maps/WreckMap';
import config from './config';
import Footer from './components/Footer';


/*const mapStyle ={
  width: '100%',
  height: '550px'
}*/

// Move this outside the component to prevent recreation on each render
const uselessWordsArray = ['vene', 'laiva', 'alus', 'soutuvene', 'purjevene', 'hylky', 'hylyn', 'hylyt', 'lentokone']

const filterAndSortWrecks = (wrecks, filters) => {
  const { wreckTypeFilters, wreckClassFilters, positionPresicionFilter, searchString } = filters
  const _ = require("lodash")

  if (wrecks.length === 0) return []

  const expStr = searchString && searchString.length > 0
    ? uselessWordsArray.filter(word => {
        const searchWords = searchString.split(' ')
        return !searchWords.includes(word)
      }).join("|")
    : uselessWordsArray.join("|")

  return _.sortBy(wrecks.filter(wreck => (
    (wreckTypeFilters.some(filter => wreck.kohdetyyppi.includes(filter)) || wreckTypeFilters[0] === 0 || wreckTypeFilters.length === 0) &&
    (wreckClassFilters.some(filter => wreck.kohdeluokitus.includes(filter)) || wreckClassFilters[0] === 0 || wreckClassFilters.length === 0) &&
    (positionPresicionFilter.some(filter => wreck.kohdetyyppi.includes(filter)) || positionPresicionFilter.length === 0) &&
    (!searchString || isWreckMatchingSearch(wreck, searchString, expStr))
  )), calculateSortIndex(searchString))
}

const isWreckMatchingSearch = (wreck, searchString, expStr) => {
  const cleanAndMatch = (text) => {
    return text.toLowerCase()
      .replace(new RegExp(`\\b(${expStr})\\b`, 'gi'), ' ')
      .replace(/\s{2,}/g, ' ')
  }

  return wreck.meta['hylytnet_muut_nimet'].some(name => {
    const cleanName = cleanAndMatch(name)
    return cleanName.includes(` ${searchString}`.toLowerCase()) ||
           cleanName.startsWith(searchString.toLowerCase())
  }) ||
  (() => {
    const cleanTitle = cleanAndMatch(wreck.title.rendered)
    return cleanTitle.startsWith(searchString.toLowerCase()) ||
           cleanTitle.includes(` ${searchString}`.toLowerCase())
  })()
}

const calculateSortIndex = (searchString) => (item) => {
  item.indexPoints = 100000 + item.index
  
  if (!searchString) return item.indexPoints

  const mainNameArr = item.title.rendered.toLowerCase().split(' ')
  const searchStrArr = searchString.toLowerCase().split(' ')

  // Check main name first word match
  if (mainNameArr[0].startsWith(searchStrArr[0])) {
    item.indexPoints = item.index
    return item.indexPoints
  }

  // Check other names first word match
  const otherNames = item.meta['hylytnet_muut_nimet']
  const foundInOtherNames = otherNames.some(othername => {
    const words = othername.toLowerCase().split(' ')
    if (words[0].startsWith(searchString)) {
      item.indexPoints = 10000 + item.index
      return true
    }
    if (words.some(word => word.startsWith(searchString))) {
      item.indexPoints = 20000 + item.index
      return true
    }
    return false
  })

  if (foundInOtherNames) return item.indexPoints

  // Check main name other words match
  if (mainNameArr.some(word => word.startsWith(searchString))) {
    item.indexPoints = 20000 + item.index
  }

  return item.indexPoints
}

const App = () => {

  const [appMarkerdata,setAppMarkerdata] = useState([])
  const [wreckTypeFilters,setWreckTypeFilters] =useState([0])
  const [wreckClassFilters,setWreckClassFilters] =useState([0])
  const [positionPresicionFilter,setPositionPresicionFilter] =useState([])
  const [filteredWrecks,setFilteredWrecks] =useState([])
  const [searchString,setSearchString]=useState(undefined)
  const [categoryCatalog,setCategoryCatalog] = useState([])
  const [categoryCatalogMain,setCategoryCatalogMain] = useState([])
  const [classificationList,setClassificationList] = useState([])
  const [path,setPath]=useState([])
  const [fitMapToMarkers,setFitMapToMarkers]= useState(true)
  const [isContentUpdated, setIsContentUpdated] = useState(false);

  // Function to handle content update
  //Fadein-fadeout -effect
  const handleContentUpdate = () => {
    console.log("handleContentUpdate()")
    setIsContentUpdated(true);

    setTimeout(() => {
      setIsContentUpdated(false);
    }, 500);
  };

 //const navigate=useNavigate()

  ///main taxonomies



  //todo split search string into array of words or count spaces and concat Titles & muut_nimet
  const filterWrecks = () => {
    console.log('filterWrecks()', new Date(), 'types', wreckTypeFilters, 'classes', wreckClassFilters, 'search', searchString)
    
    return filterAndSortWrecks(appMarkerdata, {
      wreckTypeFilters,
      wreckClassFilters, 
      positionPresicionFilter,
      searchString
    })
  }

  


  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Fetch all data in parallel
        const [markers, wreckTypes, articleTypes] = await Promise.all([
          getMarkers(),
          hylkyservices.getKohdeTyyppiTax(),
          hylkyservices.getKohdeluokitus()
        ])

        // Process markers
        const _ = require("lodash")
        const sorted = _.sortBy(markers, ['title.rendered', 'meta.hylytnet_muut_nimet'])
        sorted.forEach((item, index) => item.index = index)
        setAppMarkerdata(sorted)

        // Process wreck types
        setCategoryCatalog(wreckTypes.map(type => ({
          id: type.id,
          name: type.name,
          icon: `${type.meta.icon}`,
          parent: type.parent,
          slug: type.slug
        })))

        // Process classification list
        setClassificationList(articleTypes.map(type => ({
          id: type.id,
          name: type.name,
          slug: type.slug,
          icon: `${type.meta.icon}`
        })))
      } catch (error) {
        console.error('Error fetching initial data:', error)
        // Handle error appropriately
      }
    }

    if (appMarkerdata.length === 0) {
      fetchAllData()
    }
  }, []) // Empty dependency array since this should only run once

  useEffect(()=>{
    setCategoryCatalogMain(categoryCatalog.filter(cat =>Number(cat.parent)===0))
  },[categoryCatalog])

  
  useEffect(() => {
    if (appMarkerdata.length === 0) return

    const newFiltered = filterWrecks()
    setFilteredWrecks(newFiltered)
  }, [wreckTypeFilters, wreckClassFilters, searchString, positionPresicionFilter, appMarkerdata])

  /*useEffect(()=>{
    navigate('hylkylista')
  },[filterWrecks])*/

  const pathBooking=(pathPart)=>{
    //[{name:Ktyyppi, selection:['museo', 'kuvalliset']}]
    console.log('pathBookind',pathPart)
    if(path.length===0){
      const newPath= [pathPart]
      setPath(newPath)
    }else{
      //const changed = path.filter(pitem=>pitem.name===pathPart.name)
      const notchanged= path.filter(pitem=>pitem.name!==pathPart.name)
      const newPath= [...notchanged,pathPart].sort((a, b) => a.pathOrder - b.pathOrder)
      setPath(newPath) 
    }
  }

  const clearAllFilters=()=>{
    setFitMapToMarkers(true)
    if(wreckClassFilters && wreckClassFilters.length>0 && wreckClassFilters[0]!==0){
      setWreckClassFilters([0])
    }
    if(positionPresicionFilter && positionPresicionFilter.length>0){
      setPositionPresicionFilter([])
    }
    if(wreckTypeFilters && wreckClassFilters.length>0 && wreckTypeFilters[0] !==0){
      setWreckTypeFilters([0])
    }
    setSearchString(undefined)
    //handleContentUpdate()
    console.log('ClearAllfilters')
    
  }

  const printPath=()=>{
    if(path.length===0){
      return null
    }
    const pathWithValues= path.filter(p=>p.selection.length>0)
    if(pathWithValues.length===0){
      return null
    }
    return (
      <Container fuild>
        <center>
          <h5> 
            {pathWithValues.map(pitem=>pitem.selection.join(' tai ')).join( ' > ')}
          </h5>
          {searchString?<strong>{'Hakusana: '.concat(searchString)}</strong>:null}
        </center>
        <hr/>
      </Container> )

  }


  const renderFilters = () =>{
    /*if(pageId !==2 && pageMatch){
      return
    }*/
    return(
      <>
      <SearchForm 
        filteredWrecks={filteredWrecks} 
        searchString={searchString}
        setSearchString={setSearchString}
        categoryCatalogMain = {categoryCatalogMain}
        setPath={setPath}
        pathOrder={1}
      />
      <WreckTypeFilter
        wreckTypeFilters ={wreckTypeFilters}
        setWreckTypeFilters={setWreckTypeFilters}
        categoryCatalogMain={categoryCatalogMain}
        label={'Hylyn tyyppi'}
        setPath={pathBooking}
        pathOrder={2}
        handleContentUpdate={handleContentUpdate}
      />
      <WreckTypeFilter
        wreckTypeFilters ={wreckClassFilters}
        setWreckTypeFilters={setWreckClassFilters}
        categoryCatalogMain={classificationList}
        label={'Artikkelityyppi'}
        setPath={pathBooking}
        pathOrder={3}
        handleContentUpdate={handleContentUpdate}
      />
      {/*<WreckTypeFilter
        wreckTypeFilters ={positionPresicionFilter}
        setWreckTypeFilters={setPositionPresicionFilter}
        categoryCatalogMain={presicionMain}
        label={'Paikannustarkkuus'}
        setPath={pathBooking}
        pathOrder={4}
    />*/}

      <PositionPrecisionFilter
        setPresicion={setPositionPresicionFilter}
        presicion={positionPresicionFilter}
        categoryCatalog={categoryCatalog}
        wreckTypeFilters={wreckTypeFilters}
        label={'Paikannustarkkuus'}
        setPath={pathBooking}
        pathOrder={4}
        handleContentUpdate={handleContentUpdate}
      />
      </>
    )
  }
  
  
  /*const padding = {
    padding: 5
  }*/

  
  // TODO LISÄÄ TÄÄ mapContaineriin:: <Map style={mapStyle } />
  //      


  //const taxonomies=['uppoamispaikka','materiaali','kohdetyyppi','kohdeluokitus']

  //filterWrecks.length===1 && filterWrecks[0]>0?navi

  const pageMatch = useMatch('/page/:id')
  const pageId = pageMatch?Number(pageMatch.params.id):2
  const wreckMatch = useMatch('/hylkykortti/:id')
  const hylkykorttiId = wreckMatch?Number(wreckMatch.params.id):0
  //const catMatch = useMatch('/cat/:id')
  //const catId = catMatch?Number(catMatch.params.id):0
  //const locMatch = useMatch('/loc/:id')
  //const locId = locMatch?Number(locMatch.params.id):0

  /*if(!filterWrecks){
    return <p>Loading...</p>
  }*/
  console.log('pagematch',pageMatch,pageId)
  return (
    <div  className={`App section ${isContentUpdated ? 'updated-section' : ''}`}>
      {console.log("renderinf app.js")}
      <Container fluid ="true">
        <Row>
        <p className='tagLineHolder'>Tervetuloa sukeltaja ja meriarkeologian ystävä!</p>
        </Row>
        <Row>
          <Col >
            <h5>Hylkyihin liittyvää hajatietoa</h5>
            <Link to="/"> <div><img src="/wp-content/uploads/2014/03/logo.png" alt="Hylytnet logo"/></div></Link>
         </Col>
         <Col lg={9}>
         <Menu/>
         </Col>
        </Row>
      </Container>
      
      {/*<div className='mapContainer' >

      <Map style={mapStyle } markerdata ={appMarkerdata} setMarkerdata={setAppMarkerdata} wreckId={hylkykorttiId} catId={catId} locId={locId} /> 
      </div>
      */}
      <WreckMap 
        filteredWrecks={filteredWrecks}
        allWrecks={appMarkerdata}
        wreckId={hylkykorttiId}
        fitMapToMarkers={fitMapToMarkers}
        setFitMapToMarkers={setFitMapToMarkers}
        visibilityState={pageMatch && pageId!==2}
      />
      <div>
      {renderFilters()}
      {/*<Link style={padding} to="/">home</Link>*/}
     
        
        <Routes>
          <Route path="/cat/:id" element={
            <Hylkylista 
              category={'kohdetyyppi'} 
              wrecklist={filteredWrecks} 
            />} 
          />
          {/*<Route path="/cat" element={<Hylkylista category={'kohdetyyppi'}  wrecklist={filteredWrecks}/>} />
          <Route path="/loc/:id" element={<Hylkylista category={'kohdeluokitus'} wrecklist={filteredWrecks}/>} />
          <Route path="/materiaali/:id" element={<Hylkylista category={'materiaali'} wrecklist={filteredWrecks}/>} />*/}
          <Route path="/hylkykortti/:id" element={<Hylkykortti id={hylkykorttiId}/>} />   
          <Route path="/"
            
            element={
              <Etusivu 
                setWreckTypeFilters={setWreckTypeFilters} 
                setWreckClassFilters={setWreckClassFilters}
                setSearchString={setSearchString}
                clearAllFilters={clearAllFilters}
              />
            } 
          />
          <Route path="/page/:id" element={<Page id={pageId} handleContentUpdate={handleContentUpdate} clearAllFilters={clearAllFilters} />} />  
          <Route exact path="/hylkylista" 
            element={
              <>
                <div>
                  {printPath()}
                </div>
                <Hylkylista 
                    category={'kohdetyyppi'}  
                    wrecklist={filteredWrecks}
                />
              </>
            } 
          />
        </Routes>


    
      </div>
      
      <Footer id={config.PAGE_FOOTER} />
    </div>
  );
}

export default App;
